export const LOGO = {
    DEFAULT: '/static/img/smellyxicky-web.png',
    GIF: '/static/img/sxi_banner_movement.gif',
    STACKED:
        'https://res.cloudinary.com/jenasnelling/image/upload/c_scale,q_95,w_800/v1608181385/smellyxicky_stacked_V1_ko8lzn.png',
};

export const HEADSHOTS = {
    ICKY: [
        'https://res.cloudinary.com/jenasnelling/image/upload/c_scale,q_95,w_450/v1628888166/icky_v2_pbzkp2.png',
        'https://res.cloudinary.com/jenasnelling/image/upload/c_scale,q_95,w_450/v1628888165/Icky_v3_nyfynh.png',
        'https://res.cloudinary.com/jenasnelling/image/upload/c_scale,q_95,w_450/v1628888165/Icky_v3_nyfynh.png',
    ],
    SMELLY: [
        'https://res.cloudinary.com/jenasnelling/image/upload/c_scale,q_95,w_450/v1628888166/smelly_v2_qr8wrs.png',
        'https://res.cloudinary.com/jenasnelling/image/upload/c_scale,q_95,w_450/v1628888165/smelly_v1_oek0yq.png',
        'https://res.cloudinary.com/jenasnelling/image/upload/c_scale,q_95,w_450/v1628888165/smelly_v3_gzxib7.png',
    ],
};

export const BANNERS = [
    'https://res.cloudinary.com/jenasnelling/image/upload/c_scale,q_100,w_1000/v1620194821/sxi_banner_pink_zowspk.png',
    'https://res.cloudinary.com/jenasnelling/image/upload/c_scale,q_100,w_1000/v1620194820/sxi_banner_purple_wjqxny.png',
    'https://res.cloudinary.com/jenasnelling/image/upload/c_scale,q_100,w_1000/v1620194820/sxi_banner_teal_tmk6q5.png',
];

export const SPRITES = {
    FLY: ['/static/img/fly_mockup.png', '/static/img/fly_2.png'],
    SPLAT: [
        '/static/img/ick_mockup.png',
        '/static/img/ick_2.png',
        '/static/img/ick_3.png',
    ],
    GAS: ['/static/img/gas.png'],
};

export const MEDIA = {
    SEQUENCE: {
        name: 'Sequence EP',
        description: 'An EP by DNA',
        url: '',
        img:
            'https://res.cloudinary.com/jenasnelling/image/upload/c_scale,q_95,w_450/v1615185121/Sequence_CoverArt_FINAL_vkatty.jpg',
    },
    NO_TRESPASSING: {
        name: 'No Trespassing',
        description: 'A single by DNA, produced by Conductor Williams',
        url: '',
        img:
            'https://res.cloudinary.com/jenasnelling/image/upload/c_scale,q_95,w_450/v1629089701/NoTrespassing_DNA_h42hws.jpg',
    },
};
